




























import { Component, Prop, Watch } from 'vue-property-decorator';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import { getReservations } from '@/app/reservations/services/reservations.service';
import ReservationDto, { ReservationChannelDto, ReservationGuestDto } from '@/app/reservations/dto/reservation.dto';
import CalendarEvent from './calendar-event.vue';

interface Event {
  id: string;
  name: string;
  color: string;
  width: string;
  offset: string;
}

@Component({
  components: {
    CalendarEvent,
  },
})
export default class MonthlyCalendarWeek extends HandlesErrorMixin {
  @Prop({ type: Date, required: true })
  week!: Date;

  @Prop({ type: Date, required: true })
  month!: Date;

  @Prop({ type: String, required: true })
  apartment!: string;

  loading = false;
  reservations: ReservationDto[] = [];

  get from() {
    if (this.firstDayOfTheWeek.getMonth() === this.month.getMonth()) {
      return this.firstDayOfTheWeek;
    }

    return this.month;
  }

  get to() {
    const to = new Date(this.firstDayOfTheWeek);
    to.setDate(to.getDate() + 6);

    if (to.getMonth() === this.month.getMonth()) {
      return to;
    }

    return new Date(this.month.getFullYear(), this.month.getMonth() + 1, 0)
  }

  get offset() {
    return this.calcDateDiffInDays(this.firstDayOfTheWeek, this.from);
  }

  get width() {
    return this.dates.length;
  }

  get firstDayOfTheWeek() {
    const day = this.week.getDay();
    const date = this.week.getDate();
    const month = this.week.getMonth();
    const year = this.week.getFullYear();
    const includesFirstSunday = date - day > 0 && date === 1;
    const d = includesFirstSunday ? -6 : 1;

    return new Date(year, month, date - day + d);
  }

  get dates() {
    const dates: (Date | false)[] = [];
    const date = new Date(this.from);
    while (date <= this.to) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  get events() {
    const events: Event[] = [];
    this.reservations.forEach(({ id, arrival, departure, guest, channel }) => {
      const name = this.getEventName(guest);
      const color = this.getEventColor(channel);
      const width = this.getEventWidth(arrival, departure);
      const offset = this.getEventOffset(arrival);

      events.push({ id: id as string, name, color, width, offset })
    });

    return events;
  }

  getEventName(guest: ReservationGuestDto) {
    const firstname = guest?.firstname || '-';
    const lastname = guest?.lastname || '-';

    return `${firstname} ${lastname}`;
  }

  getEventColor(channel: ReservationChannelDto | null) {
    switch (channel?.name) {
      case 'Airbnb':
        return '#ff585d';
      case 'Homepage':
        return '#fecd0c';
      case 'Booking.com':
        return '#003580';
      default:
        return 'grey';
    }
  }

  getEventWidth(arrival: string, departure: string) {
    const oneDay = 1000 * 60 * 60 * 24;
    const start = new Date(arrival.substr(0, 10));
    const end = new Date(departure.substr(0, 10));
    const boundedStart = new Date(Math.max(this.from.getTime() - oneDay, start.getTime()));
    const boundedEnd = new Date(Math.min(this.to.getTime() + oneDay, end.getTime()));
    const intervalLength = this.calcDateIntervalLength(boundedStart, boundedEnd);

    return `${intervalLength * 40 - 2}px`;
  }

  getEventOffset(arrival: string) {
    const oneDay = 1000 * 60 * 60 * 24;
    const start = new Date(arrival.substr(0, 10));
    const boundedStart = new Date(Math.max(this.from.getTime() - oneDay, start.getTime()));
    const diffInDays = this.calcDateDiffInDays(this.from, boundedStart);
    const offset = diffInDays;

    return `${offset * 40 + 21}px`;
  }

  calcDateDiffInDays(start: Date, end: Date) {
    const oneDayInMilliseconds = 1000 * 60 * 60 * 24;

    const diffInMilliseconds = end.getTime() - start.getTime();
    const diffInDays = Math.ceil(diffInMilliseconds / oneDayInMilliseconds);

    return diffInDays;
  }

  calcDateIntervalLength(start: Date, end: Date) {
    return this.calcDateDiffInDays(start, end);
  }

  @Watch('week')
  @Watch('apartment', { immediate: true })
  async getReservations() {
    this.loading = true;

    try {
      const isoEnd = `${this.to.toISOString().substr(0, 10)}T23:59:59`;
      const isoStart = `${this.from.toISOString().substr(0, 10)}T00:00:00`;

      const response = await getReservations({
        apartmentId: this.apartment,
        from: isoStart,
        to: isoEnd,
      });

      this.reservations = response.data.reservations;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
