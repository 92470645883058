














import sum from 'lodash/sum';
import round from 'lodash/round';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import DoughnutChart from '@/app/core/components/charts/doughnut-chart.vue';
import NightsByChannel from '../dto/nights-by-channel.dto';
import FetchChartsDataDto from '../dto/fetch-charts-data.dto';
import { fetchNightsByChannel } from '../services/dashboard.service';

interface TooltipItem {
  index: number;
  datasetIndex: number;
}

interface Dataset {
  data: number[];
}

interface Data {
  labels: string[];
  datasets: Dataset[];
}

@Component({
  components: {
    DoughnutChart,
  },
})
export default class ReservationsByPlatformChart extends HandlesErrorMixin {
  @Prop({ type: Object, required: true }) filters!: FetchChartsDataDto;

  loading = 0;
  noData = {
    value: 1,
    label: 'no-data',
  }
  data: NightsByChannel[] = [this.noData];

  get labels() {
    return this.data.map(({ label }) => ((label === 'Homepage') ? 'Hosttate' : label));
  }

  get values() {
    return this.data.map((datum) => datum.value);
  }

  get total() {
    return sum(this.values);
  }

  get colors() {
    return this.labels.map((label) => this.color(label));
  }

  get title() {
    const nights = this.$t('label.nights').toString();
    const platforms = this.$t('label.platform').toString();

    return `${nights} / ${platforms}`;
  }

  color(label: string) {
    switch (label) {
      case 'Airbnb':
        return '#ff585d';
      case 'Hosttate':
        return '#fecd0c';
      case 'Booking.com':
        return '#003580';
      default:
        return 'grey';
    }
  }

  get chartOptions() {
    return {
      maintainAspectRatio: false,
      cutoutPercentage: 80,
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: ({ index, datasetIndex }: TooltipItem, data: Data) => {
            const label = data.labels[index];
            const value = data.datasets[datasetIndex].data[index];
            const percent = round((value * 100) / this.total, 2);
            const nights = this.$t('label.nights').toString();

            if (label === 'no-data') {
              return ` ${this.$t('label.noData')}`;
            }

            return ` ${label}: ${value} ${nights} (${percent}%)`;
          },
        },
      },
    };
  }

  get chartData() {
    return {
      labels: this.labels,
      datasets: [{
        hoverOffset: 4,
        label: this.title,
        data: this.values,
        backgroundColor: this.colors,
      }],
    };
  }

  @Emit('setLoading')
  setLoading(loading: number) {
    this.loading += loading;
    return loading;
  }

  @Watch('filters', { immediate: true, deep: true })
  async getData() {
    this.setLoading(1);

    try {
      let { data } = await fetchNightsByChannel(this.filters);
      if (data.length === 0) {
        data = [this.noData];
      }
      this.data = data;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(-1);
    }
  }
}
