
























































































import { Component, Prop, Watch } from 'vue-property-decorator';
import ReservationDto from '@/app/reservations/dto/reservation.dto';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import { getReservationCheck } from '@/app/reservations/services/reservations.service';
import ReservationCheckDto, { ReservationCheckType } from '@/app/reservations/dto/reservation-check.dto';
import ReservationPopover from '@/app/reservations/components/reservation-popover.vue';

@Component({
  components: {
    ReservationPopover,
  },
})
export default class UpcomingReservation extends HandlesErrorMixin {
  @Prop({ type: Date, required: true })
  from!: Date;

  @Prop({ type: Number, required: true })
  index!: number;

  x = 0;
  y = 0;
  menu = false;
  loading = false;
  check: ReservationCheckDto | null = null;

  get popoverWidth() {
    const mobile = this.$vuetify.breakpoint.smAndDown;
    return mobile ? '300px' : '450px';
  }

  showMenu(event: MouseEvent) {
    event.preventDefault()
    this.menu = false;
    this.x = event.clientX;
    this.y = event.clientY;
    this.$nextTick(() => {
      this.menu = true
    })
  }

  get isCheckIn() {
    if (!this.check) return false;

    return this.check.type === ReservationCheckType.CheckIn;
  }

  get nights() {
    if (!this.check) return 0;

    const { arrival, departure } = this.check.reservation as ReservationDto;
    const from = new Date(arrival.substr(0, 10)).getTime();
    const to = new Date(departure.substr(0, 10)).getTime();
    const oneDay = 1000 * 60 * 60 * 24;

    return Math.ceil((to - from) / oneDay + Number.EPSILON);
  }

  get people() {
    if (!this.check) return 0;

    const { adults, children } = this.check.reservation as ReservationDto;

    return (adults ?? 0) + (children ?? 0);
  }

  get guest() {
    if (!this.check) return '';

    const reservation = this.check.reservation as ReservationDto;
    const { firstname, lastname } = reservation.guest;

    return `${firstname} ${lastname}`;
  }

  @Watch('index', { immediate: true })
  async getReservationCheck() {
    this.loading = true;

    try {
      const res = await getReservationCheck({
        index: this.index,
        from: this.from.toISOString(),
      });
      this.check = res.data;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
