
















import { Component, Watch } from 'vue-property-decorator';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import { getReservationChecksCount } from '@/app/reservations/services/reservations.service';
import UpcomingReservation from './upcoming-reservation.vue';

@Component({
  components: {
    UpcomingReservation,
  },
})
export default class UpcomingReservations extends HandlesErrorMixin {
  count = 0;
  loading = false;
  now = new Date();
  from = new Date(
    this.now.getFullYear(),
    this.now.getMonth(),
    this.now.getDate(),
  );

  get items() {
    return Array.from({ length: this.count }, (k, v) => v);
  }

  @Watch('from', { immediate: true })
  async getCount() {
    this.loading = true;

    try {
      const res = await getReservationChecksCount({ from: this.from.toISOString() });
      this.count = res.data.count;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
