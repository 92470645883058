




























import { Component, Prop, Vue } from 'vue-property-decorator';
import MonthlyCalendarWeek from './monthly-calendar-week.vue';

@Component({
  components: {
    MonthlyCalendarWeek,
  },
})
export default class MonthlyCalendarMonth extends Vue {
  @Prop({ type: Date, required: true })
  month!: Date;

  @Prop({ type: String, required: true })
  apartment!: string;

  loading = false;

  get daysOfTheWeek() {
    const month = this.from.getMonth();
    const year = this.from.getFullYear();
    const day = this.from.getDate() - this.from.getDay() + 1;

    const daysOfTheWeek: Date[] = [];
    [0, 1, 2, 3, 4, 5, 6].forEach((d) => {
      daysOfTheWeek.push(new Date(year, month, day + d));
    });

    return daysOfTheWeek;
  }

  get from() {
    const month = this.month.getMonth();
    const year = this.month.getFullYear();

    return new Date(year, month);
  }

  get to() {
    const month = this.month.getMonth();
    const year = this.month.getFullYear();

    return new Date(year, month + 1, 0);
  }

  get weeks() {
    const weeks: Date[] = [];
    const week = new Date(this.from);
    while (week <= this.to) {
      weeks.push(new Date(week));
      week.setDate(week.getDate() + 7);
    }
    return weeks;
  }
}
