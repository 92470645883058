import ApartmentDto from '@/app/apartments/dto/apartment.dto';
import ReservationDto from './reservation.dto';

export enum ReservationCheckType {
  CheckIn = 'check-in',
  CheckOut = 'check-out',
}

export default interface ReservationCheckDto {
  apartment?: ApartmentDto | string;
  reservation?: ReservationDto | string;
  time: string;
  date: Date | string;
  type: ReservationCheckType;
}
