






























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import ReservationPopover from '@/app/reservations/components/reservation-popover.vue';

@Component({
  components: {
    ReservationPopover,
  },
})
export default class CalendarEvent extends Vue {
  @Prop({ type: String, required: true })
  id!: string;

  @Prop({ type: String, required: true })
  name!: string;

  @Prop({ type: String, required: true })
  color!: string;

  @Prop({ type: String, required: true })
  width!: string;

  @Prop({ type: String, required: true })
  offset!: string;

  x = 0;
  y = 0;
  menu = false;

  get popoverWidth() {
    const mobile = this.$vuetify.breakpoint.smAndDown;
    return mobile ? '300px' : '450px';
  }

  @Emit('reservationCancelled')
  onReservationCancelled() {
    return this.id;
  }

  showMenu(event: MouseEvent) {
    event.preventDefault()
    this.menu = false;
    this.x = event.clientX;
    this.y = event.clientY;
    this.$nextTick(() => {
      this.menu = true
    })
  }
}
