

















































import round from 'lodash/round';
import VueSpeedometer from 'vue-speedometer';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import { fetchOccupancyPercent } from '../services/dashboard.service';
import FetchChartsDataDto from '../dto/fetch-charts-data.dto';

@Component({
  components: {
    VueSpeedometer,
  },
})
export default class OccupancyGaugeChart extends HandlesErrorMixin {
  @Prop({ type: Object, required: true }) filters!: FetchChartsDataDto;

  value = 0;
  loading = 0;

  @Emit('setLoading')
  setLoading(loading: number) {
    this.loading += loading;
    return loading;
  }

  @Watch('filters', { immediate: true, deep: true })
  async getData() {
    this.setLoading(1);

    try {
      const { data } = await fetchOccupancyPercent(this.filters);
      this.value = round(data, 2);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(-1);
    }
  }
}
