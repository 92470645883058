







































































import { Component, Prop, Watch } from 'vue-property-decorator';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import ReservationEventDto from '@/app/reservations/dto/reservation-event.dto';
import { getReservationEvent } from '@/app/reservations/services/reservations.service';
import ReservationDto, { ReservationType } from '@/app/reservations/dto/reservation.dto';
import ReservationPopover from '@/app/reservations/components/reservation-popover.vue';

@Component({
  components: {
    ReservationPopover,
  },
})
export default class ActivityFeedItem extends HandlesErrorMixin {
  @Prop({ type: Number, required: true })
  index!: number;

  x = 0;
  y = 0;
  menu = false;
  loading = false;
  item: ReservationEventDto | null = null;

  get popoverWidth() {
    const mobile = this.$vuetify.breakpoint.smAndDown;
    return mobile ? '300px' : '450px';
  }

  showMenu(event: MouseEvent) {
    event.preventDefault()
    this.menu = false;
    this.x = event.clientX;
    this.y = event.clientY;
    this.$nextTick(() => {
      this.menu = true
    })
  }

  get typeColor() {
    switch (this.item?.type) {
      case ReservationType.Reservation:
        return 'success';
      case ReservationType.Modification:
        return 'info';
      case ReservationType.Cancellation:
        return 'error';
      default:
        return 'grey';
    }
  }

  get typeIcon() {
    switch (this.item?.type) {
      case ReservationType.Reservation:
        return 'mdi-calendar-plus';
      case ReservationType.Modification:
        return 'mdi-calendar-edit';
      case ReservationType.Cancellation:
        return 'mdi-calendar-remove';
      default:
        return 'mdi-calendar-sync';
    }
  }

  get channelColor() {
    switch (this.channel) {
      case 'Airbnb':
        return '#ff585d';
      case 'Hosttate':
        return '#1b283b';
      case 'Booking.com':
        return '#003580';
      default:
        return 'grey';
    }
  }

  get channel() {
    const reservation = this.item?.reservation as ReservationDto;

    switch (reservation.channel?.name) {
      case 'Airbnb':
      case 'Booking.com':
        return reservation.channel.name;
      case 'Homepage':
        return 'Hosttate';
      default:
        return 'Blocking';
    }
  }

  get guest() {
    const reservation = this.item?.reservation as ReservationDto;
    const { firstname, lastname } = reservation.guest;

    return `${firstname || ''} ${lastname || ''}`
  }

  @Watch('index', { immediate: true })
  async getReservationCheck() {
    this.loading = true;

    try {
      const res = await getReservationEvent({ index: this.index });
      this.item = res.data;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
