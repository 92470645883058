import { render, staticRenderFns } from "./activity-feed-item.vue?vue&type=template&id=7fc89ba6&scoped=true&"
import script from "./activity-feed-item.vue?vue&type=script&lang=ts&"
export * from "./activity-feed-item.vue?vue&type=script&lang=ts&"
import style0 from "./activity-feed-item.vue?vue&type=style&index=0&id=7fc89ba6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fc89ba6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VChip,VIcon,VListItem,VListItemAvatar,VListItemContent,VListItemTitle,VMenu,VProgressCircular,VTimelineItem})
