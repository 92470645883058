























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import MonthPicker from '@/app/core/components/month-picker.vue';
import ApartmentSelect from '@/app/core/components/apartment-select.vue';
import FetchChartsDataDto from '../dto/fetch-charts-data.dto';

@Component({
  components: {
    MonthPicker,
    ApartmentSelect,
  },
})
export default class ChartsSectionFilters extends Vue {
  @Prop({ type: Number, default: false }) loading!: number;
  @Prop({ type: Object, required: true }) filters!: FetchChartsDataDto;

  allowedFromDates(from: string) {
    return from <= this.filters.to.substr(0, 7);
  }

  allowedToDates(to: string) {
    return to >= this.filters.from.substr(0, 7);
  }

  @Emit('apartmentChanged')
  onApartmentChange(apartment: string) {
    return apartment;
  }

  @Emit('toChanged')
  onToChange(to: string) {
    return to;
  }

  @Emit('fromChanged')
  onFromChange(from: string) {
    return from;
  }
}
