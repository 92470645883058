
























import { Component, Prop, Watch } from 'vue-property-decorator';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import { getReservations } from '@/app/reservations/services/reservations.service';
import ReservationDto, { ReservationChannelDto, ReservationGuestDto } from '@/app/reservations/dto/reservation.dto';
import CalendarEvent from './calendar-event.vue';

interface Event {
  id: string;
  name: string;
  color: string;
  width: string;
  offset: string;
}

@Component({
  components: {
    CalendarEvent,
  },
})
export default class MultiCalendarRow extends HandlesErrorMixin {
  @Prop({ type: Date, required: true })
  from!: Date;

  @Prop({ type: String, required: true })
  apartmentId!: string;

  @Prop({ type: Array, required: true })
  dates!: Date[];

  reservations: ReservationDto[] = [];

  get startDate() {
    return new Date(this.from.getFullYear(), this.from.getMonth());
  }

  get endDate() {
    return new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 6, 0);
  }

  get events() {
    const events: Event[] = [];
    this.reservations.forEach(({ id, arrival, departure, guest, channel }) => {
      const name = this.getEventName(guest);
      const color = this.getEventColor(channel);
      const width = this.getEventWidth(arrival, departure);
      const offset = this.getEventOffset(arrival);

      events.push({ id: id as string, name, color, width, offset })
    });

    return events;
  }

  getEventName(guest: ReservationGuestDto) {
    const firstname = guest?.firstname || '-';
    const lastname = guest?.lastname || '-';

    return `${firstname} ${lastname}`;
  }

  getEventColor(channel: ReservationChannelDto | null) {
    switch (channel?.name) {
      case 'Airbnb':
        return '#ff585d';
      case 'Homepage':
        return '#fecd0c';
      case 'Booking.com':
        return '#003580';
      default:
        return 'grey';
    }
  }

  getEventWidth(arrival: string, departure: string) {
    const oneDay = 1000 * 60 * 60 * 24;
    const start = new Date(arrival.substr(0, 10));
    const boundedStart = new Date(Math.max(this.startDate.getTime() - oneDay, start.getTime()));
    const end = new Date(departure.substr(0, 10));
    const boundedEnd = new Date(Math.min(this.endDate.getTime() + oneDay, end.getTime()));
    const intervalLength = this.calcDateIntervalLength(boundedStart, boundedEnd);

    return `${intervalLength * 40 - 2}px`;
  }

  getEventOffset(arrival: string) {
    const oneDay = 1000 * 60 * 60 * 24;
    const start = new Date(arrival.substr(0, 10));
    const boundedStart = new Date(Math.max(this.startDate.getTime() - oneDay, start.getTime()));
    const diffInDays = this.calcDateDiffInDays(this.startDate, boundedStart);
    const offset = diffInDays;

    return `${offset * 40 + 21}px`;
  }

  calcDateDiffInDays(start: Date, end: Date) {
    const oneDayInMilliseconds = 1000 * 60 * 60 * 24;

    const diffInMilliseconds = end.getTime() - start.getTime();
    const diffInDays = Math.ceil(diffInMilliseconds / oneDayInMilliseconds);

    return diffInDays;
  }

  calcDateIntervalLength(start: Date, end: Date) {
    return this.calcDateDiffInDays(start, end);
  }

  isToday(date: Date) {
    const today = new Date();

    return (
      today.getFullYear() === date.getFullYear()
      && today.getMonth() === date.getMonth()
      && today.getDate() === date.getDate());
  }

  @Watch('from', { immediate: true })
  async getReservations() {
    try {
      const isoEnd = this.endDate.toISOString();
      const isoStart = this.startDate.toISOString();

      const response = await getReservations({
        apartmentId: this.apartmentId,
        from: isoStart,
        to: isoEnd,
      });

      this.reservations = response.data.reservations;
    } catch (error) {
      this.handleError(error);
    }
  }
}
