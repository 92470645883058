











import { Component, Vue } from 'vue-property-decorator';
import ActivityFeed from './activity-feed.vue';
import UpcomingReservations from './upcoming-reservations.vue';

@Component({
  components: {
    ActivityFeed,
    UpcomingReservations,
  },
})
export default class ActivitySection extends Vue {}
