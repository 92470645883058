

























import { Component, Vue } from 'vue-property-decorator';
import MultiCalendar from './multi-calendar.vue';
import MonthlyCalendar from './monthly-calendar.vue';
import CalendarSectionHeader from './calendar-section-header.vue';

@Component({
  components: {
    MultiCalendar,
    MonthlyCalendar,
    CalendarSectionHeader,
  },
})
export default class CalendarSection extends Vue {
  tab = 0;
  apartment = '';
  loading = false;
  from = new Date();

  onTabChange(tab: number) {
    this.tab = tab;
  }

  onFromChange(from: Date) {
    this.from = from;
  }

  onApartmentChange(apartment: string) {
    this.apartment = apartment;
  }
}
