

























































import { Component, Prop, Watch } from 'vue-property-decorator';
import ApartmentDto from '@/app/apartments/dto/apartment.dto';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import { getApartments } from '@/app/apartments/services/apartments.service';
import MultiCalendarRow from './multi-calendar-row.vue';

interface Month {
  index: number;
  month: string;
  span: number;
}

@Component({
  components: {
    MultiCalendarRow,
  },
})
export default class MultiCalendar extends HandlesErrorMixin {
  @Prop({ type: Date, required: true })
  from!: Date;

  loading = false;
  apartments: ApartmentDto[] = [];

  get months() {
    const months: Month[] = [];

    const startDate = new Date(this.from.getTime());
    for (let i = 0; i < 12; i += 2) {
      startDate.setMonth(startDate.getMonth() + 1);
      startDate.setDate(0);

      const month = this.$moment(startDate).format('MMMM');
      const span = startDate.getDate() - 15;
      months.push({ index: i, month, span: 15 });
      months.push({ index: i + 1, month, span });

      startDate.setDate(1);
      startDate.setMonth(startDate.getMonth() + 1);
    }

    return months;
  }

  get dates() {
    const date = new Date(this.from.getTime());
    date.setDate(1);

    const endDate = new Date(this.from.getTime());
    endDate.setMonth(endDate.getMonth() + 6);
    endDate.setDate(0);

    const dates: Date[] = [];
    while (date <= endDate) {
      dates.push(new Date(date.getTime()));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  isToday(date: Date) {
    const today = new Date();

    return (
      today.getFullYear() === date.getFullYear()
      && today.getMonth() === date.getMonth()
      && today.getDate() === date.getDate());
  }

  @Watch('from', { immediate: true })
  async getApartments() {
    this.loading = true;

    try {
      const res = await getApartments();
      this.apartments = res.data.items;
    } catch (err) {
      this.handleError(err);
    } finally {
      this.loading = true;
    }
  }
}
