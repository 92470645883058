


















import { Component } from 'vue-property-decorator';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import { getReservationEventsCount } from '@/app/reservations/services/reservations.service';
import ActivityFeedItem from './activity-feed-item.vue';

@Component({
  components: {
    ActivityFeedItem,
  },
})
export default class ActivityFeed extends HandlesErrorMixin {
  count = 0;
  loading = false;

  get items() {
    return Array.from({ length: this.count }, (k, v) => v);
  }

  async getCount() {
    this.loading = true;

    try {
      const res = await getReservationEventsCount();
      this.count = res.data.count;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    this.getCount();
  }
}
