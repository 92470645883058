











import { Component, Prop, Vue } from 'vue-property-decorator';
import MonthlyCalendarMonth from './monthly-calendar-month.vue';

@Component({
  components: {
    MonthlyCalendarMonth,
  },
})
export default class MonthlyCalendar extends Vue {
  @Prop({ type: Date, required: true })
  from!: Date;

  @Prop({ type: String, required: true })
  apartment!: string;

  loading = false;

  get months() {
    const months: Date[] = [];

    const from = new Date(this.from.getFullYear(), this.from.getMonth());
    for (let i = 0; i < 6; i += 1) {
      months.push(new Date(from.getTime()));
      from.setMonth(from.getMonth() + 1);
    }

    return months;
  }
}
