















import round from 'lodash/round';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import LineChart from '@/app/core/components/charts/line-chart.vue';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import FetchChartsDataDto from '../dto/fetch-charts-data.dto';
import { fetchOccupancyPercentByMonth, fetchRevenueByMonth } from '../services/dashboard.service';

interface TooltipItem {
  index: number;
  datasetIndex: number;
}

interface Dataset {
  label: string;
  data: number[];
}

interface Data {
  labels: string[];
  datasets: Dataset[];
}

@Component({
  components: {
    LineChart,
  },
})
export default class OccupancyAndRevenueChart extends HandlesErrorMixin {
  @Prop({ type: Object, required: true }) filters!: FetchChartsDataDto;

  loading = 0;
  dates = this.calcDates();
  revenueByMonth = [0, 0, 0, 0];
  occupancyByMonth = [0, 0, 0, 0];

  chartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: ({ index, datasetIndex }: TooltipItem, data: Data) => {
          const { label } = data.datasets[datasetIndex];
          const value = data.datasets[datasetIndex].data[index];

          if (datasetIndex === 0) {
            return ` ${label}: $${value}`
          }

          return ` ${label}: ${value}%`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          id: this.$t('label.revenue').toString(),
          type: 'linear',
          position: 'left',
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: !this.isMobile,
            labelString: this.$t('label.revenue').toString(),
          },
          ticks: {
            beginAtZero: true,
          },
        },
        {
          id: this.$t('label.occupancy').toString(),
          type: 'linear',
          position: 'right',
          scaleLabel: {
            display: !this.isMobile,
            labelString: this.$t('label.occupancy').toString(),
          },
          ticks: {
            beginAtZero: true,
            suggestedMax: 100,
            callback(value: number) {
              return `${value}%`;
            },
          },
        },
      ],
    },
  };

  get occupancyData() {
    return this.occupancyByMonth.map(percent => round(percent, 2));
  }

  get revenueData() {
    return this.revenueByMonth.map(revenue => round(revenue, 2));
  }

  calcDates() {
    const to = new Date(this.filters.to);
    const from = new Date(this.filters.from);
    const months = [];
    while (from <= to) {
      months.push(new Date(from.getTime()));
      from.setMonth(from.getMonth() + 1);
    }

    return months;
  }

  get labels() {
    return this.dates.map((date) => this.$moment(date).format('MMMM, YYYY'));
  }

  get chartData() {
    return {
      labels: this.labels,
      datasets: [
        {
          fill: false,
          borderWidth: 2,
          borderDash: [6],
          borderColor: '#1b283b',
          backgroundColor: '#1b283b',
          label: this.$t('label.revenue').toString(),
          yAxisID: this.$t('label.revenue').toString(),
          data: this.revenueData,
          hoverOffset: 4,
        },
        {
          fill: false,
          borderWidth: 2,
          borderDash: [6],
          borderColor: '#fecd0c',
          backgroundColor: '#fecd0c',
          label: this.$t('label.occupancy').toString(),
          yAxisID: this.$t('label.occupancy').toString(),
          data: this.occupancyData,
          hoverOffset: 4,
        },
      ],
    };
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  @Emit('setLoading')
  setLoading(loading: number) {
    this.loading += loading;
    return loading;
  }

  @Watch('filters', { immediate: true, deep: true })
  async getOccupancyPercentByMonth() {
    this.setLoading(1);

    try {
      const { data } = await fetchOccupancyPercentByMonth(this.filters);
      this.dates = this.calcDates();
      this.occupancyByMonth = data;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(-1);
    }
  }

  @Watch('filters', { immediate: true, deep: true })
  async getRevenueByMonth() {
    this.setLoading(1);

    try {
      const { data } = await fetchRevenueByMonth(this.filters);
      this.dates = this.calcDates();
      this.revenueByMonth = data;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(-1);
    }
  }
}
