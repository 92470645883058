






import { Component, Vue, Prop } from 'vue-property-decorator';
import Money, { Currency } from '../dto/money.dto';

@Component
export default class MoneyView extends Vue {
  @Prop({ type: [Number, Object], required: true })
  money!: Money;

  get value() {
    if (!this.money) return 0;
    if (typeof this.money === 'number') return this.money;

    return this.money.value;
  }

  get currency() {
    if (!this.money) return Currency.USD;
    if (typeof this.money === 'number') return Currency.USD;

    return this.money.currency;
  }
}
