















































































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import MonthPicker from '@/app/core/components/month-picker.vue';
import ApartmentSelect from '@/app/core/components/apartment-select.vue';

@Component({
  components: {
    MonthPicker,
    ApartmentSelect,
  },
})
export default class CalendarSection extends Vue {
  tab = 0;
  sheet = false;
  loading = false;
  apartment = null;

  @Prop({ type: Date, required: true })
  from!: Date;

  @Emit('fromChanged')
  onFromChange(from: Date) {
    return new Date(from);
  }

  @Watch('apartment')
  @Emit('apartmentChanged')
  onApartmentChange() {
    return this.apartment;
  }

  @Watch('tab')
  @Emit('tabChanged')
  onTabChange() {
    return this.tab;
  }
}
