var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('label.occupancy'))+" ")]),_c('v-card-text',{staticClass:"speedometer-container"},[_c('vue-speedometer',{attrs:{"height":180,"value":_vm.value,"maxValue":100,"ringWidth":25,"paddingVertical":15,"needleColor":"#1b283b","currentValueText":"${value}%","needleTransition":"easeElastic","needleTransitionDuration":3000,"customSegmentStops":[0, 20, 40, 60, 80, 100],"customSegmentLabels":[
        {
          text: "20%",
          color: "#555",
          position: "OUTSIDE",
        },
        {
          text: "40%",
          color: "#555",
          position: "OUTSIDE",
        },
        {
          text: "60%",
          color: "#555",
          position: "OUTSIDE",
        },
        {
          text: "80%",
          color: "#555",
          position: "OUTSIDE",
        },
        {
          text: "100%",
          color: "#555",
          position: "OUTSIDE",
        } ]}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }