





















































































































import { Getter, State } from 'vuex-class';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import AuthModule from '@/app/auth/store';
import UserDto from '@/app/user/dto/user.dto';
import MoneyView from '@/app/core/components/money-view.vue';
import ApartmentDto from '@/app/apartments/dto/apartment.dto';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import ConfirmationDialog from '@/app/core/components/confirmation-dialog.vue';
import { cancelReservation, getReservation } from '../services/reservations.service';
import ReservationDto, { ReservationType } from '../dto/reservation.dto';

@Component({
  components: {
    MoneyView,
  },
})
export default class ReservationInfo extends HandlesErrorMixin {
  @State((state) => state.user)
  private user!: UserDto;

  @Getter
  private isHostAdmin!: typeof AuthModule.prototype.isHostAdmin;

  @Prop({ type: String, required: true })
  private reservationId!: string;

  loading = false;
  cancelling = false;
  reservation: ReservationDto | null = null;

  get firstname() {
    return this.reservation?.guest?.firstname || '-';
  }

  get lastname() {
    return this.reservation?.guest?.lastname || '-';
  }

  get arrival() {
    return this.reservation?.arrival || '-';
  }

  get departure() {
    return this.reservation?.departure || '-';
  }

  get people() {
    const adults = this.reservation?.adults || 0;
    const children = this.reservation?.children || 0;

    return adults + children;
  }

  get price() {
    return this.reservation?.payment?.price || 0;
  }

  get isCancellable() {
    const can = this.isHostAdmin || this.isOwner;
    const cancellable = (this.isBlocking || this.isFromHosttate) && !this.isCancelled;

    return cancellable && can;
  }

  get isOwner() {
    return this.reservation?.owner?.id === this.user.id;
  }

  get isFromHosttate() {
    return this.reservation?.channel?.name === 'Homepage';
  }

  get isBlocking() {
    return this.reservation?.channel?.name === 'Blocked channel';
  }

  get isCancelled() {
    return this.reservation?.type === ReservationType.Cancellation;
  }

  @Emit('reservationCancelled')
  reservationCancelled() {
    return this.reservationId;
  }

  @Watch('reservationId', { immediate: true })
  async getReservation() {
    this.loading = true;

    try {
      const response = await getReservation(this.reservationId);
      this.reservation = response.data;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }

  async onCancel() {
    const apartment = (this.reservation?.apartment as ApartmentDto).name;
    const title = this.$t('title.cancelReservation');
    const message = this.$t('message.confirmCancelReservation', { apartment });
    const confirm = await this.$dialog.open(ConfirmationDialog, { title, message });

    if (confirm) {
      this.cancelling = true;
      try {
        await cancelReservation(this.reservationId as string);
        this.$notify.success(this.$t('success.reservationCancelled').toString());
        this.reservationCancelled();
      } catch (err) {
        this.handleError(err);
      } finally {
        this.cancelling = false;
      }
    }
  }
}
