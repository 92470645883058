import api from '@/app/core/services/api.service';
import NightsByChannel from '../dto/nights-by-channel.dto';
import FetchChartsDataDto from '../dto/fetch-charts-data.dto';

export const fetchOccupancyPercent = (params: FetchChartsDataDto) => (
  api.get<number>('/reservations/occupancy-percent', { params })
);

export const fetchOccupancyPercentByMonth = (params: FetchChartsDataDto) => (
  api.get<number[]>('/reservations/occupancy-percent-by-month', { params })
);

export const fetchNightsByChannel = (params: FetchChartsDataDto) => (
  api.get<NightsByChannel[]>('/reservations/nights-by-channel', { params })
);

export const fetchRevenueByMonth = (params: FetchChartsDataDto) => (
  api.get<number[]>('/reservations/revenue-by-month', { params })
);

export default {
  fetchRevenueByMonth,
  fetchNightsByChannel,
  fetchOccupancyPercent,
  fetchOccupancyPercentByMonth,
};
