





























import { Component, Vue } from 'vue-property-decorator';
import ChartsSection from '../components/charts-section.vue';
import ActivitySection from '../components/activity-section.vue';
import CalendarSection from '../components/calendar-section.vue';

@Component({
  components: {
    ChartsSection,
    ActivitySection,
    CalendarSection,
  },
  metaInfo(this: Dashboard) {
    return { title: this.$t('pageTitle.dashboard').toString() };
  },
})
export default class Dashboard extends Vue {}
