



















































































import { Component, Vue } from 'vue-property-decorator';
import { lastDayOfMonth } from '@/app/core/util';
import FetchChartsDataDto from '../dto/fetch-charts-data.dto';
import OccupancyGaugeChart from './occupancy-gauge-chart.vue';
import ChartsSectionFilters from './charts-section-filters.vue';
import OccupancyAndRevenueChart from './occupancy-and-revenue-chart.vue';
import ReservationsByPlatformChart from './reservations-by-platform-chart.vue';

enum Chart {
  OCCUPANCY_GAUGE_CHART = 0,
  RESERVATIONS_BY_PLATFORM_CHART = 1,
  OCCUPANCY_AND_REVENUE_CHART = 2,
}

@Component({
  components: {
    OccupancyGaugeChart,
    ChartsSectionFilters,
    OccupancyAndRevenueChart,
    ReservationsByPlatformChart,
  },
})
export default class ChartsSection extends Vue {
  loading = 0;
  currentChart = Chart.OCCUPANCY_GAUGE_CHART;

  filters: FetchChartsDataDto = {
    apartment: '*',
    to: this.initialTo(),
    from: this.initialFrom(),
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  setLoading(loading: number) {
    this.loading += loading;
  }

  onApartmentChanged(apartment: string) {
    this.filters.apartment = apartment;
  }

  onToChanged(to: string) {
    const date = new Date(to);
    const year = date.getFullYear();
    const month = date.getMonth();

    date.setDate(lastDayOfMonth(year, month))

    this.filters.to = date.toISOString();
  }

  onFromChanged(from: string) {
    const date = new Date(from);

    this.filters.from = date.toISOString();
  }

  initialFrom() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();

    const yearDelta = Number(month < 3);
    const threeMonthAgo = (month - 3 + 12) % 12;

    date.setHours(13);
    date.setMonth(threeMonthAgo);
    date.setFullYear(year - yearDelta);

    return date.toISOString();
  }

  initialTo() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();

    date.setHours(14);
    date.setDate(lastDayOfMonth(year, month))

    return date.toISOString();
  }
}
