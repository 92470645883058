



























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component
export default class MonthPicker extends Vue {
  showMenu = false;

  @Prop({ type: Object, default: {} })
  menuOptions!: unknown;

  @Prop({ type: String, default: 'MMMM, YYYY' })
  format!: string;

  @Prop({ type: String })
  value!: string;

  get valuePrefix() {
    return this.value.substr(0, 7);
  }

  get valueSuffix() {
    return this.value.substr(10);
  }

  @Emit('input')
  input(value: string) {
    this.showMenu = false;
    return `${value}-02${this.valueSuffix}`;
  }
}
