
import { Doughnut, mixins } from 'vue-chartjs'
import { Component, Mixins, Prop } from 'vue-property-decorator';

@Component
export default class DoughnutChart extends Mixins(Doughnut, mixins.reactiveProp) {
  @Prop({ type: Object, default: null }) options!: object;

  mounted() {
    this.renderChart(this.chartData, this.options)
  }
}
